import React from "react"
import dayjs from "dayjs"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import { Layout, Tag, Author, RichText, Seo } from "../components/Shared"
import { CoverYourHome } from "../components/Sections"
import { convertToDateWithSuffix } from "../utilities"

import BlobTall from "../images/Blob-tall.svg"

const BlogArticle = ({ data }) => {
  const article = data.article
  return (
    <Layout>
      <Seo
        url={data.site && data.site.siteMetadata.url + "/blog/" + article.slug}
        title={article.seo && article.seo.title}
        description={
          article.seo.metaDescription &&
          article.seo.metaDescription.metaDescription
        }
        image={
          article.seo &&
          article.seo.image &&
          article.seo.image.file &&
          article.seo.image.file.url
        }
      />
      <main className="wrapper-width wrapper-height blog-article-page">
        <section>
          <div className="blog-article-page-header">
            <Img
              fluid={article.featuredImage.fluid}
              title={article.featuredImage.title}
              alt={article.featuredImage.title}
            />
            <h1 className="heading-large blog-article-page-title">
              {article.title}
            </h1>
            <span className="blog-article-page-post-date">
              {convertToDateWithSuffix(dayjs(article.updatedAt).format("D")) +
                " "}
              {dayjs(article.updatedAt).format("MMMM YYYY")}
            </span>
            <div className="blog-article-page-tags">
              {article.tags.length > 0 &&
                article.tags.map((tag) => <Tag key={tag.id} tag={tag.tag} />)}
            </div>
          </div>
          <RichText content={article.article} />
          <Author
            authorName={article.author.authorName}
            authorPhoto={article.author.authorImage.fluid}
            authorPhotoDescription={article.author.authorImage.title}
          />
        </section>
        <aside className="blog-article-page-sidebar">
          <section className="blog-article-page-sidebar-content">
            <div className="blog-article-page-sidebar-blob">
              <BlobTall />
              <Img
                className="blog-article-page-blob-image"
                fluid={data.sidebarContent.image.fluid}
                alt={data.sidebarContent.image.title}
                title={data.sidebarContent.image.title}
              />
            </div>
            <h2 className="heading-medium">{data.sidebarContent.heading}</h2>
            <p>
              Get free repairs on your boiler this winter with a Home Assist
              cover plan for only £4/mo.
            </p>
            <Link to="/home-cover-plans" className="button-primary">
              Cover your home
            </Link>
            <Link to="/quote" className="button-secondary">
              Get a new boiler
            </Link>
          </section>
        </aside>
      </main>
      <CoverYourHome backgroundColour="grey-lightest" />
    </Layout>
  )
}

export default BlogArticle

export const pageQuery = graphql`
  query getBlogArticle($id: String!) {
    site {
      siteMetadata {
        url
      }
    }
    article: contentfulBlogArticles(contentful_id: { eq: $id }) {
      contentful_id
      title
      updatedAt
      slug
      featuredImage {
        fluid {
          ...GatsbyContentfulFluid
        }
        title
      }
      tags {
        id
        tag
      }
      featuredArticle
      article {
        raw
        references {
          # contentful_id is required to resolve the references
          contentful_id
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
      author {
        authorName
        authorImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
      seo {
        image {
          file {
            url
          }
        }
        metaDescription {
          metaDescription
        }
        title
      }
    }
    sidebarContent: contentfulPageSections(
      contentful_id: { eq: "1Uqajy8NPea9CxWC61MNdR" }
    ) {
      contentful_id
      image {
        fluid {
          ...GatsbyContentfulFluid
        }
        title
      }
      heading
    }
  }
`
